<script>
	import { algoliasearch } from 'algoliasearch';
	export let searchSvgUrl;

	const applicationID = process.env.ALGOLIA_APP_ID;
	const apiKey = process.env.ALGOLIA_API_KEY;
	const algoliaClient = algoliasearch(applicationID, apiKey);

	window.aa('init', { appId: applicationID, apiKey: apiKey });

	let querySuggestions = [];
	let indices = [
		{
			id: 0,
			label: 'westcoastuniversity.edu',
			index_name: 'wcu_wp_searchable_posts',
		},
		{
			id: 1,
			label: '2024 - 2025 Catalog',
			index_name: 'wcu_catalog_2024_2025',
		},
	];
	let searchInputPlaceholder = 'Search westcoastuniversity.edu';
	let noResultsFound = false;
	let showResults = true;
	let activeIndex = 0;
	let queryID = '';
	let hits = [];
	let showPlaceholder = true;
	let searchInputValue = '';
	let searchTimeout;

	$: showPlaceholder = searchInputValue.length < 3;
	$: if (searchInputValue) {
		if (searchInputValue.length > 2) {
			clearTimeout(searchTimeout);
			searchTimeout = setTimeout(() => {
				performSearch(searchInputValue);
			}, 400);
		} else {
			hits = [];
		}
	}

	$: searchInputPlaceholder =
		activeIndex === 0
			? 'Search westcoastuniversity.edu'
			: "Search WCU's 2024 - 2025 Catalog";

	async function fetchQuerySuggestions() {
		try {
			const response = await algoliaClient.searchSingleIndex({
				indexName: 'wcu_wp_searchable_posts_query_suggestions',
			});
			querySuggestions = response.hits.slice(0, 8);
		} catch (error) {
			console.error('Error fetching query suggestions:', error);
		}
	}

	fetchQuerySuggestions();

	async function performSearch(query) {
		// console.log('performSearch Fired');

		try {
			const response = await algoliaClient.searchSingleIndex({
				indexName: indices[activeIndex].index_name,
				searchParams: { query, clickAnalytics: true },
			});
			queryID = response.queryID;
			hits = response.hits;
			noResultsFound = hits.length === 0;
			if (showResults === false) {
				showResults = true;
			}
		} catch (error) {
			console.error('Error fetching search results:', error);
		}
	}

	function handleIndexChange(event) {
		// console.log('id = ', event.target.value);
		// console.log('activeIndex = ', activeIndex);
		if (event.target.value === activeIndex) return;
		showResults = false;
		performSearch(searchInputValue);
	}

	function formatToPlainEnglish(str, isCourse = false) {
		const lowercaseWords = ['and', 'of', 'by'];
		let words = str.replace(/^\//, '').split('-');
		words = words.map((word, index) =>
			lowercaseWords.includes(word.toLowerCase()) && index !== 0
				? word.toLowerCase()
				: word.charAt(0).toUpperCase() + word.slice(1)
		);
		if (isCourse) words.shift();
		return words.join(' ');
	}

	function parseHitTypeWcuDotEdu(hit) {
		if (hit.is_program_page === 1) return 'Programs';
		if (hit.post_type_label === 'Posts') return 'Blog';
		if (!hit.url.paths.path_2) return '';
		let hitType = formatToPlainEnglish(hit.url.paths.path_2);
		if (hitType === 'Tuition Financial Aid')
			hitType = 'Tuition & Financial Aid';
		return hitType;
	}

	function parseHitTypeCatalog(hit) {
		if (hit.url.paths.path_4) {
			let hitType = formatToPlainEnglish(hit.url.paths.path_4);
			if (hitType === 'Faculty by Location' && hit.url.paths.path_5) {
				hitType += ` - ${formatToPlainEnglish(hit.url.paths.path_5)}`;
			} else if (hitType === 'Courses' && hit.url.paths.path_5) {
				hitType += ` - ${formatToPlainEnglish(hit.url.paths.path_5, true)}`;
			}
			return hitType;
		}
		return 'Catalog';
	}

	function searchResultClicked(hit) {
		const objectIDs = [hit.objectID];
		const positions = [
			hits.findIndex((obj) => obj.objectID === hit.objectID) + 1,
		];

		window.aa('clickedObjectIDsAfterSearch', {
			index: indices[activeIndex].index_name,
			eventName: 'Search Result Clicked',
			queryID,
			objectIDs,
			positions,
		});

		sessionStorage.setItem('algoliaQueryID', queryID);
		sessionStorage.setItem('algoliaObjectID', JSON.stringify(objectIDs));
	}
</script>

<div id="search-wrap" class="search-wrapper">
	<label for="search-input">
		Search
		<img src={searchSvgUrl} alt="" />
		<input
			id="search-input"
			type="text"
			placeholder={searchInputPlaceholder}
			aria-label="Search"
			name="s"
			bind:value={searchInputValue}
		/>
	</label>
	<button class="search-close" on:click={() => (searchInputValue = '')}>
		<span>Close Search</span>
	</button>
	<ul id="search-ui">
		<li class="index-selector">
			<span style="margin-right: 20px;">Search within:</span>
			{#each indices as index}
				<label>
					<input
						type="radio"
						name="index-selection"
						value={index.id}
						bind:group={activeIndex}
						on:change={handleIndexChange}
					/>
					{index.label}
				</label>
			{/each}
		</li>
		<li>
			<ul id="suggested-terms">
				<span>Suggested searches:</span>
				{#each querySuggestions as suggestion}
					<li class="suggested-term">
						<button
							class="suggested-term-btn"
							on:click={() => (searchInputValue = suggestion.query)}
						>
							{suggestion.query}
						</button>
					</li>
				{/each}
			</ul>
		</li>
		<li id="results-container">
			<ul id="search-results">
				{#if showPlaceholder}
					<li id="placeholder">What can we help you find?</li>
				{:else if noResultsFound}
					<li>
						No results found for "{searchInputValue}". Can't find what you're
						looking for? Call
						<a href="tel:+1-866-508-2684">866-508-2684</a> or start a live chat.
					</li>
				{:else if activeIndex === 0 && showResults === true}
					{#each hits as hit}
						<li>
							<p class="hit-type">{parseHitTypeWcuDotEdu(hit)}</p>
							<a
								href={hit.url.path}
								target={hit.url.path}
								on:click={() => searchResultClicked(hit)}
							>
								<h2>{hit.post_title}</h2>
								<p>
									{@html hit._snippetResult?.meta_description?.value ||
										hit.meta_desc_truncated}
								</p>
							</a>
						</li>
					{/each}
				{:else if activeIndex === 1 && showResults === true}
					{#each hits as hit}
						<li>
							<p class="hit-type">{parseHitTypeCatalog(hit)}</p>
							<a
								href={hit.url.permalink}
								target="_blank"
								on:click={() => searchResultClicked(hit)}
							>
								<h2>{hit.post_title}</h2>
								<p>{@html hit._snippetResult?.content?.value || ''}</p>
							</a>
						</li>
					{/each}
				{/if}
			</ul>
		</li>
	</ul>
</div>
